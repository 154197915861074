<script>
  import { get, call } from 'vuex-pathify'

  export default {
    data() {
      return {
        addedTimeout: null,
      }
    },
    computed: {
      ...get('config', ['marketplaceEnabled', 'currentSeason']),
      ...get('general', ['navigating']),
      season() {
        return this.currentSeason?.toLowerCase() ?? 'fall'
      },
    },
    created() {
      clearTimeout(this.addedTimeout)
      this.addedTimeout = setTimeout(() => {
        this.clearCart()
        if (this.$route.params.subscribe) {
          this.$navigate({ name: 'SubscribeWelcome' })
        } else if (this.marketplaceEnabled) {
          this.$navigate({ name: 'Market' })
        } else {
          this.$navigate({ name: 'Home' })
        }
      }, 3000)
    },
    methods: {
      ...call('choicePlusCart', ['clearCart']),
    },
  }
</script>

<template>
  <section class="pt-20 overflow-hidden">
    <BaseContainer max-width="sm">
      <div class="text-center mb-8">
        <h1 class="font-heading font-medium text-4xl">Thank you!</h1>
        <p> We will let you know when your items are ready to ship. </p>
      </div>
      <BaseImage :src="`/static/thank-you-${season}.gif`" alt="" responsive="sm" />
    </BaseContainer>
  </section>
</template>
